@import 'common.scss';

.non-gdt-analytics {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__kde__select {
    min-width: 400px;
  }

  &__toolbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__spinner {
      margin-right: auto;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__scatter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;

    &__item {
      &__controls {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
}
