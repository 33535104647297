.embeddings-metrics {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__controls {
    display: flex;
    gap: 16px;
  }

  &__plots {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    height: 400px;
  }
}
