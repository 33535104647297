@import 'common.scss';

.cf-textarea {
  width: 100%;
  // min-height: 64px;
  //max-height: 400px;
  //height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: $border-radius;
  border: 1px solid $dark65;
  background-color: $dark100;
  font-size: 14px;
  color: $textPrimary;
  padding: 15px;
  box-sizing: border-box;
  padding-right: 60px;
  resize: none;
  line-height: 2;
  font-family: 'Open Sans', Roboto;

  &:focus {
    border: 1px solid $cfCyan;
    box-shadow: 0px 0px 6px 0px rgba($cfCyan, 0.25);
    outline: none;
  }
}
