@import 'common.scss';
.cf-spinner {
  display: inline-block;
  width: 10px;
  aspect-ratio: 1 / 1;
  border: 2px solid $dark65;
  border-left-color: #818a91;
  border-radius: 50%;
  animation: spin 1s infinite linear;
  margin-right: 0.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
