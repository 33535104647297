@import 'common.scss';

.cf-toast {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  min-width: 400px;
  width: fit-content;
  border-radius: 0.5rem;
  z-index: 10000000;

  &.success {
    background-color: $ok-color;
  }

  &.info {
    background-color: $cfCyan;
    color: $dark70;
  }

  &.warning {
    background-color: $cfYellow;
    color: $dark70;
  }

  &.error {
    background-color: $error-color;
  }

  &-content {
    flex-grow: 1;
    text-align: start;
  }

  &-close {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.cf-toast-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem;
  z-index: 1000000;
}

.toast-enter {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s, transform 0.5s;
}

.toast-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.toast-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.toast-exit-active {
  opacity: 0;
  transform: translateY(-20px);
}
