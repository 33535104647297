@import 'common.scss';

.cf-select {
  position: relative;
  outline: none;

  &:not(.disabled):focus &-input {
    border: 1px solid $cfCyan;
  }

  &-input {
    cursor: pointer;
    border: 1px solid #59595f;
    background-color: $dark100;
    border-radius: 8px;
    min-height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 8px;
    gap: 8px;

    &.disabled {
      background-color: $dark80;
    }

    &:not(.disabled):hover,
    &:not(.disabled).focused {
      border: 1px solid $cfCyan;
    }

    &-expand {
      cursor: pointer;
    }
  }

  &-chevron {
    margin-left: auto;
    padding-right: 6px;
    color: $dark30;
  }

  &-dropdown {
    top: calc(100% + 8px);
    border-radius: 8px 8px 4px 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    width: 100%;
    position: absolute;
    z-index: 1100;
    box-sizing: border-box;
    background-color: $dark80;

    &.top {
      top: unset;
      bottom: calc(45px);
    }

    &.searchable {
      top: 0;
    }

    &-actions {
      padding: 8px 12px;
      border-top: 1px solid;

      & > * {
        color: $cfCyan;
        cursor: pointer;
      }
    }
  }

  &-items {
    padding: 4px 0;
    max-height: 300px;
    overflow: auto;
  }

  &-noOptions {
    text-align: center;
    color: hsl(0, 0%, 60%);
    padding: 8px 12px;
    box-sizing: border-box;
  }
}
