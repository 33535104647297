@import 'common.scss';

.organization-picker {
  padding: calc(100% / 8);
  .card-item {
    background-color: $darkmode_4;
    border-radius: $border-radius;
    min-width: 200px;
    max-width: 200px;
    height: 220px;
    display: flex;
    flex-direction: column;

    & > :first-child {
      flex: 1 1 auto;
    }

    & > :last-child {
      flex: 0 1 auto;
      padding: 10px;
      background-color: $darkmode_8;
      border-top: 1px solid white;
      border-radius: 0 0 $border-radius $border-radius;
    }
  }
}
