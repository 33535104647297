@import 'common.scss';

.analytics {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 0.1fr 0.05fr 2.4fr;
  grid-template-areas:
    'cards cards'
    'controls controls'
    'main main';

  & > .controls {
    position: relative;
    grid-area: controls;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;

    .backfill-modal-wrapper {
      top: 50px;
    }
  }

  .section-title {
    color: $dark30;
    text-align: left;
    font-size: 20px;
    font-weight: $semibold;
    text-transform: uppercase;
  }

  .agg-selector {
    margin-left: auto;
  }
}

.always-visible {
  position: sticky;
  top: 0px;
  right: 16px;
  z-index: 10000;
}

.cf-portal .detailed-app-performance-modal {
  background-color: $cardNestedBkgd;
  width: 80%;
  height: 80%;
  overflow-y: auto;
}
