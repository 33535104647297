@import 'common.scss';

.model-cohort {
  .constrained-width {
    width: 365px;
  }

  .model-cohort-tab {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-modal {
      width: 820px;
    }
  }

  .ratios {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  .error-message {
    background-color: $error-color;
    padding: 5px 10px;
    border-radius: 3px;
    width: fit-content;
  }

  &-modal {
    width: 820px;
  }
}

.preview-cohort {
  margin-top: 24px;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #262833;
  display: flex;
  justify-content: space-between;

  &-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  &-icon {
    cursor: pointer;
  }
}

.preview-modal {
  width: 820px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 715px;

  &-title {
    font-size: 26px;
    font-weight: 500;
    line-height: normal;
  }

  &-download {
    margin-left: auto;
  }

  .preview-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .preview-item {
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: 1px solid #59595f;
  }
}
