@import 'common.scss';

.week-viewer {
  background-color: $cardNestedBkgd;
  border-radius: 8px;
  max-height: 350px;
  overflow: auto;

   /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $table-bg;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $table-scroll-color;
    border-radius: 20px;
  }

  .week-days {
    display: flex;
    flex-direction: row;
    font-weight: $semibold;

    .day {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      flex: 1 1 auto;
      color: $dark50;
      text-align: center;
      min-height: 200px;

      border-right: 1px solid $dark65;
      .name {
        font-size: 16px;
        text-transform: uppercase;
      }

      .value {
        font-size: 22px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.today {
          color: black;
          background-color: $cfCyan;
          border-radius: 50%;
        }
      }
    }

    & > :last-child {
      border-right: none;
    }
  }

  .hours {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 15px;

    .scheduled-hour {
      border-radius: 36px;
      padding: 6px 18px;
      background-color: $cfDarkCyan;
      color: $cfCyan;
      font-weight: $semibold;
    }
  }
}
