@import 'common.scss';

.cf-select-cohort-item {
  padding: 12px 16px;
  background-color: $dark80;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;

  &:hover,
  &.focused {
    background-color: $dark70;
  }

  &__title {
    font-size: 14px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__description {
    font-size: 12px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
