.data-cell-container {
  width: 32px;
  display: flex;
  justify-content: center;

  .data-cell {
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(148, 213, 219, 0.8), rgba(148, 213, 219, 0.6));

    &.negative {
      background: radial-gradient(circle at center, rgba(217, 95, 75, 0.8), rgba(217, 95, 75, 0.6));
    }

    &.radius-3 {
      width: 32px;
      height: 32px;
    }

    &.radius-2 {
      width: 16px;
      height: 16px;
    }

    &.radius-1 {
      width: 8px;
      height: 8px;
    }
  }
}
