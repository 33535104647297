@import 'common.scss';

.chart {
  padding-bottom: 0px;
  text-align: left;

  font-size: 18px;
  font-family: 'Open Sans';
  font-weight: $semibold;

  .tooltip {
    color: $textPrimary;
    background-color: $dark80;
    border-color: $dark80;
    padding: 0px;
    margin: 0px;
  }
}

.cf-tooltip-text {
  color: $textPrimary;
  background-color: $dark80;
}

.chart-header {
  display: flex;
  gap: 10px;
  justify-content: space-between;

  &__title {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-content: stretch;
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: normal;
  }
}
