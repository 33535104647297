@import 'common.scss';

.model-target {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
