@import 'common.scss';

.date-time-range-picker {
  text-align: left;
  color: $secondary-font-color;
  position: relative;

  .date-time-range-input {
    height: 40px;
    border-radius: $border-radius;
    border: 1px solid $input-border-color;
    color: white;
    text-align: left;
    padding: 0 40px 0 10px;
    box-sizing: border-box;
    background: $dark100 url(assets/calendar.png) calc(100% + 30px) no-repeat;
    background-origin: content-box;
    min-width: 200px;

    &:focus {
      border: 1px solid $cfCyan;
      box-shadow: 0px 0px 6px 0px rgba($cfCyan, 0.25);
      outline: none;
    }
  }

  & .use-bootstrap {
    // here the styles to override the default ones from daterangepicker
    color: black;

    input {
      height: 30px;
    }
  }
}
