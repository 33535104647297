@import 'common.scss';

.tag {
  white-space: nowrap;
  border-radius: $border-radius;
  border: 1px solid $gray60;
  min-width: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: $semibold;

  &-xmark {
    cursor: pointer;
  }

  &.large {
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.small {
    padding: 1px 6px 1px 6px;
  }

  &.extrasmall {
    padding: 1px 6px 1px 6px;
    font-size: smaller;
    min-width: min-content;
  }

  &.neutral {
  }

  &.system {
    background-color: $cfCyan;
    border-color: $dark90;
    color: $dark100;
  }

  &.white {
    background-color: $dark20;
    color: black;
    border-color: $dark20;
  }

  &.warn {
  }

  &.error {
    background-color: $error-color;
    border-color: $error-color;
  }
}
