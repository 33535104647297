@import 'common.scss';

.cf-chip {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 18px;
  border-radius: 36px;
  color: $cfCyan;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  box-sizing: border-box;
  border: 1px solid $cfDarkCyan;
  font-size: smaller;

  &.clickable {
    cursor: pointer;
  }

  &.small {
    padding: 2px 8px;
    border-radius: 6px;
  }

  &.primary {
    background-color: $cfDarkCyan;
  }

  &.secondary {
    border: 1px solid $cfDarkCyan;
  }

  &__cross {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
