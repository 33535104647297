@import 'common.scss';

.intervention-summary {
  @extend .card;

  color: white;

  display: flex;
  gap: 20px;

  & > :first-child {
    flex: 1 1 auto;
    font-size: 18px;
    font-weight: 600;
  }
}
