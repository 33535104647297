@import 'common.scss';

.cf-table {
  height: 100%;
  overflow-y: auto;

  &.table-primary {
    color: $table-text-color;
    background-color: $cardBkgd;

    th {
      background-color: $cardBkgd;
    }
  }

  &.table-secondary {
    color: $table-text-color;
    background-color: $cardNestedBkgd;

    th {
      background-color: $cardNestedBkgd;
    }
  }
  border-radius: 8px;

  .cf-header {
    padding: 20px 25px 10px 25px;
    font-weight: bold;
    text-align: left;
    font-size: 18px;
    color: $table-title-color;
    text-transform: capitalize;
  }

  .cf-table-fix-head {
    overflow: auto;
  }

  .cf-table-fix-head thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    tr {
      padding: 10px;
    }

    th,
    td {
      height: 40px;
      padding: 5px;
    }

    tr {
      border-bottom: 1px solid $table-border-color;

      &:hover {
        background-color: $table-row-hover;
      }

      .row-selected {
        background-color: $table-selected-row;
      }

      &.row-expanded {
        border-bottom: none;
        background-color: $cardNestedBkgd;
      }
      &.expanded {
        background-color: $cardNestedBkgd;
      }
    }
  }

  .cf-empty-table-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $table-bg;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $table-scroll-color;
    border-radius: 20px;
  }
}
