@import 'common.scss';

.cf-navigation-arrows {
  grid-area: arrows;
  gap: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;

  svg rect {
    fill: $darkCfCyan60;
  }

  svg path {
    stroke: $Gray300;
  }

  &__nav {
    cursor: pointer;

    &.disabled {
      rect {
        fill: $Gray500;
      }

      path {
        stroke: $Gray400;
      }
    }
  }

  .rotate {
    transform: scaleX(-1);
  }
}
