@import 'common.scss';

.new-nudge-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .nudge-body-title {
    font-size: 18px;
    font-weight: $semibold;
    margin: 20px 0;
  }

  .named-control {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .oneline {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;

    & > * {
      flex: 1 1 auto;
    }
  }
}
