@import 'common.scss';

.substitution-request {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__options {
    display: flex;
    gap: 15px;
  }

  .replace-key {
    color: $error-color;
  }
}
