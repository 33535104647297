.schedule-definition {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .calendar-with-week {
    display: flex;
    width: 100%;
    gap: 20px;

    & > .week-viewer {
      width: 100%;
    }
  }
}

.scheduling-editor {
  width: 800px;
  &__controls {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
