@import '../intervention.scss';
@import 'common.scss';

.nudge-policy {
  @extend .interventionSection;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .selected-nudge-section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.new-nudge-wrapper {
  display: flex;
  justify-content: center;
  padding: 100px 150px;
  overflow-y: auto;

  .cf-button {
    z-index: 1;
  }
}
