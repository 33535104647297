@import 'common.scss';

.sample-viewer {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 12px;

  .sample-item {
    cursor: pointer;
    text-align: center;
    padding: 10px 4px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid $dark40;
  }
}
