@import 'common.scss';

.model-details {
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    padding: 40px;
    height: 180px;
  }

  .trait-tags {
    display: flex;
    gap: 4px;

    & > * {
      display: flex;
    }
  }
}
