@import 'common.scss';

.cf-predefined-range {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: $dark80;
  border-radius: 0.5rem;
  z-index: 99;
  min-width: 240px;
  overflow: hidden;

  .cf-predefined-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.25rem;
    padding: 0.75rem 1rem;
    color: $light;

    cursor: pointer;

    &:hover {
      background-color: $dark65;
    }
  }
}
