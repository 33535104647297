@import 'common.scss';

$separation: 8px;

.cf-nav-list {
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: row;

  margin-bottom: 24px;
  border-bottom: 1px solid $dark40;
  text-align: left;

  .nav-list-item {
    color: $nav-item-inactive;
    margin-right: 50px;
    cursor: pointer;
    text-transform: capitalize;

    &.selected {
      color: $nav-item-active;
      font-weight: $semibold;
      opacity: 1;

      padding: 1px;
      border-bottom: 4px solid $cfCyan;
      padding-bottom: $separation;
    }
  }

  & > .actions {
    display: flex;
    align-self: right;
    gap: 10px;
    margin-left: auto;
  }

  &.style {
    &-no-underline {
      border: none;
      text-align: unset;

      .selected {
        padding: 0;
        border: none;
      }
    }

    &-buttons {
      border: none;
      gap: 24px;

      .nav-list-item {
        margin-right: 0;
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid $darkCfCyan20;

        color: $darkCfCyan20;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .selected {
        border: none;

        padding:8px 12px;
        border-radius: 4px;
        background: $darkCfCyan20;

        font-family: Open Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

        color: #141C26;
      }
    }
  }
}
