@import './common.scss';

html,
body,
#root {
  height: 100%;
  font-family: Open Sans, Tomato Grotesk, Arial;
  color: $textPrimary;
  overflow: hidden;
}

body {
  background: $background-color;
  font-size: 14px;
}

.app-spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $header-bg-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-login {
  display: grid;
  height: 100%;
}

.AppLogin {
  background: $portal-bg;
  height: 100%;
}

.App {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1.9fr;
  gap: 0px;
  grid-template-areas:
    'header'
    'main';

  &.with-chat {
    grid-template-columns: 1fr minmax(250px, 0.3fr);
    grid-template-rows: 0.1fr 1.9fr;
    gap: 0px;
    grid-template-areas:
      'header header'
      'main chat';
  }

  & > .header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  & > .main {
    position: relative;
    grid-area: main;
    overflow: auto;
    padding: 20px 106px 40px 106px;

    & > .sidepanel-switcher {
      position: fixed;
      bottom: 16px;
      cursor: pointer;
      z-index: 1000;
    }
  }

  & > .chat {
    overflow-y: auto;
    box-sizing: border-box;
    background: $cardNestedBkgd;
    //    padding: 24px;
  }
}

.navigation {
  grid-area: navigation;
}

// remove this when stop using prime react, just for the initial mockad
li {
  color: white !important;
}
