@import 'common.scss';

.intervention-participants {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .action-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-right: 24px;

    & > :first-child {
      flex: 1 1 auto;
    }
  }

  .reduced {
    width: 30%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title-size {
      margin-bottom: 0;
    }
  }

  .subject-view {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 12px;
    .subject-item {
      text-align: center;
      padding: 10px 4px;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid $dark40;
    }
  }

  .subjects-title {
    font-size: 18px;
    font-weight: $semibold;
    padding: 25px 40px;
  }
}
