@import 'common.scss';

$gap-size: 24px;

.intervention-metrics {
  display: flex;
  flex-direction: column;

  gap: $gap-size;

  .reward-chart {
    @extend .card;
    border: none;
    padding: 0px;

    & > * {
      border: none;
      padding: 0px;
    }
  }

  .chart-action {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
