.censoring-target {
  & > * {
    width: 360px;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & > :first-child {
      min-width: 120px;
    }
  }
}
