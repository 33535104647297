@import 'common.scss';

.kpi-matrix-container {
  display: grid;

  grid-template-columns: minmax(300px, 0.5fr) 1fr 1fr;
  grid-template-rows: 1.8fr 0.2fr;
  gap: 10px;
  width: 100%;

  grid-template-areas:
    'all matrix matrix'
    'footer footer footer';

  & > :first-child {
    grid-area: all;
    height: unset;
    min-width: 250px;
  }

  .kpi-matrix {
    grid-area: matrix;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    gap: 10px;
  }

  .kpi-footer {
    color: $dark40;
    grid-area: footer;
    text-align: left;
    display: flex;
    justify-content: space-between;

    .note {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 12px;

      .icon {
        border: 1px solid $dark40;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        padding: 2px;
      }
    }
  }
}
