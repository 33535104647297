@import 'common.scss';

.cf-editable-field-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.cf-editable-field--input {
  padding: 7px;
  border-radius: 6px;
  font-size: 14px;
  background: $cardBkgd;
  border: 1px solid $dark65;
  outline: none;
  color: $light;
}

.cf-editable-field--input:read-only {
  background-color: transparent;
  border-color: transparent;
}

.cf-editable-field--actions {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.cf-editable-field--icon {
  padding: 3px;
}

.cf-editable-field--action {
  display: flex;
}
