@import 'common.scss';

.internal-admin-tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.2fr 2.6fr 0.2fr;
  gap: 10px 10px;
  grid-template-areas:
    'url url'
    'request response'
    'controls controls';

  .url {
    grid-area: url;
    display: flex;
    gap: 10px;
    & > :nth-child(1) {
      min-width: 100px;
    }

    & > :nth-child(2) {
      flex: 1 1 auto;
    }
  }

  .controls {
    grid-area: controls;
  }

  .request {
    grid-area: request;
    height: 300px;
    overflow-y: auto;
  }

  .response {
    grid-area: response;
    border-radius: $border-radius;
    color: $textPrimary;
    background-color: $dark100;
    border: 1px solid $dark65;
    text-align: left;
    padding: 5px;
    box-sizing: border-box;

    height: 300px;
    overflow-y: auto;

    ul {
      background-color: unset !important;
    }
  }
}
