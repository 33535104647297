.nudge-adoption {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .control {
    align-self: flex-end;
  }

  & .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
