.header-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  & > .legend {
    height: 4px;
    max-width: 24px;
    border-radius: 10px;
  }
}
