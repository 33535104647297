.metrics-section {
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__row {
    border-top: 1px solid #59595f;
    padding: 0.25rem;
    align-items: center;

    display: flex;
    & > :first-child {
      width: 500px;
    }

    & > :nth-child(2) {
      width: 200px;
    }

    & > :nth-child(3) {
      width: 100%;
      text-transform: capitalize;
    }
  }
}
