@import 'common.scss';

.api-key-viewer {
  background-color: $card-bg;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-content: stretch;
  justify-content: flex-start;
  align-items: flex-start;
}
