@import 'common.scss';

.model-tag-list {
  .tag-list {
    display: flex;
    gap: 5px;
    align-items: center;

    &__action {
      cursor: pointer;
    }
  }
}
