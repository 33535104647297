.chunk-renderer {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .chunk-title {
    display: flex;
    gap: 5px;

    & > :last-child {
      margin-left: auto;
    }
    padding: 3px;
  }
}
