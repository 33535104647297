@import '../intervention.scss';

.intervention-definition {
  @extend .interventionSection;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.8fr 1.2fr;
  gap: 24px;
  grid-template-areas:
    'name type algorithm'
    'description description description';

  .description {
    grid-area: description;
  }

  .name {
    grid-area: name;
    width: 100%;
  }

  .type {
    grid-area: type;
  }

  .algorithm {
    grid-area: algorithm;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > :first-child {
      margin-bottom: -5px;
    }
  }

  .test-definition {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
