@import 'common.scss';

.group-participants {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .groupname {
    font-weight: 700;
    font-size: 18px;
  }

  .inline {
    display: flex;
    gap: 10px;
  }

  .subjects-length {
    width: 200px;
  }

  .sample-viewer {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 12px;

    .sample-item {
      text-align: center;
      padding: 10px 4px;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid $dark40;
    }
  }
}
