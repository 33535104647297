@import 'common.scss';

.model-metrics {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &-controls {
    display: flex;
    gap: 16px;
  }

  &-scores {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-plots {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.2fr 1fr;

    gap: 10px;
    grid-template-areas:
      'lifetime lifetime'
      'lifetimeNext survival';

    .lifetime {
      grid-area: lifetime;
      width: 100%;
    }
    .lifetimeNext {
      grid-area: lifetimeNext;
    }
    .survival {
      grid-area: survival;
    }
  }
}
