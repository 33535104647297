@import 'common.scss';

.sample-cohort-comparator {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 50px;

  grid-auto-flow: row;

  .stats-title {
    font-size: 14px;
    font-weight: $semibold;
  }
}
