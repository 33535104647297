@import 'common.scss';

.list-of-adopted-nudges {
  .adopted-nudges-container {
    @extend .card;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .adopted-header {
    display: flex;
    gap: 10px;
    justify-items: center;
    align-items: center;
    padding: 5px;

    & > :nth-child(2) {
      padding-right: 20px;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .calendar-with-week {
    display: flex;
    gap: 16px;
    // width: 100%;

    .week-viewer {
      width: 100%;
    }
  }

  .adopted-nudge-details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
  }
}
