@import 'common.scss';

.textarea-submit {
  position: relative;
  background-color: $dark100;
  border-radius: 6px;
  border: 1px solid #59595f;
  padding: unset;

  textarea {
    border: unset;

    &:focus {
      outline: none;
      border: 1px solid #4caf5000; /* Cambio visual en el borde */
      box-shadow: none;
    }
  }

  &__cited {
    padding: 5px;
  }

  &__button {
    position: absolute;
    bottom: 18px;
    right: 10px;
    cursor: pointer;

    display: flex;

    &.disabled {
      svg path {
        stroke: $Gray500;
      }
    }
  }
}
