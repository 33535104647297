@import 'common.scss';

.cf-select-searchField {
  min-height: 40px;
  box-sizing: border-box;
  border: 1px solid $cfCyan;
  border-radius: 8px;
  padding: 8px;
  background-color: $dark100;
  display: flex;
  gap: 8px;
  align-items: center;
}

.cf-select-searchInput {
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  background-color: $dark100;
  border: 0;
  outline: none;
  color: #fff;
}
