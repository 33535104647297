@import 'common.scss';

.trait-explorer {
  width: 1000px;
  height: 700px;

  &.fullWidth {
    width: unset;
  }

  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 20px;

  grid-template-areas:
    'filters selected'
    'filters data'
    'filters footer';

  box-sizing: border-box;

  &__data {
    overflow-y: auto;
    grid-area: data;
  }

  &__selected {
    grid-area: selected;
    max-height: 150px;
    overflow-y: auto;
  }

  &__controls {
    grid-area: filters;
    display: flex;
    flex-direction: column;
    gap: 10px;

    overflow-y: auto;

    &__search {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  &__footer {
    grid-area: footer;

    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
