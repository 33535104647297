@import 'common.scss';

.cf-card-picker {
  .title {
    color: $dark30;
    text-align: left;
    font-size: 20px;
    font-weight: $semibold;
    text-transform: uppercase;
  }

  .cards {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(auto, 200px));
    grid-gap: 40px;
    padding-top: 20px;
    cursor: pointer;
  }
}
