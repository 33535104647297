@import 'common.scss';

.sensitivity-chart {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__footer {
    display: flex;
    justify-content: flex-end;

    .sensitivity-legend {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid $dark70;
    }
  }

  .item-legend {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;

    & > :first-child {
      flex: 1 1 auto;
      align-items: center;
    }
  }
}
