@import 'common.scss';

.expanded-timeserie {
  width: 80%;
  padding: 10px;
  @extend .card;

  &__header {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;

    :first-child {
      margin-right: auto;
    }
  }

  & > div > .cf-line-chart {
    border: unset;
  }

  &.light {
    & > div > .cf-line-chart {
      background-color: white;
    }
  }
}
