body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Open Sans';
  line-height: 24px;
}

@font-face {
  font-family: 'TomatoGrotesk';
  src: local('TomatoGroteskBold'), url('./assets/fonts/tomato-grotesk/woff2/TomatoGroteskBold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'TomatoGrotesk';
  src: local('TomatoGroteskRegular'),
    url('./assets/fonts/tomato-grotesk/woff2/TomatoGroteskRegular.woff2') format('woff2');
  font-weight: bold;
}

.daterangepicker {
  color: black;
}
