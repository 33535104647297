.cf-wizard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 85%;

  .steps {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .cf-wizard-container {
    position: fixed;
    right: 50px;
  }

  @media (max-width: 1000px) {
    width: 100%;

    .cf-wizard-container {
      display: none;
    }
  }
}
