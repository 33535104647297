@import 'common.scss';

.manual-sample-info {
  .groups {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .extendable-viewer {
      background-color: $cardNestedBkgd;
      padding: 16px 24px;
      border-radius: 8px;
    }
  }
}
