.user-engagement {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__charts {
    display: flex;
    gap: 10px;

    & > * {
      width: 50%;
    }
  }

  .sub-tabs {
    margin-top: 14px;
  }
}
