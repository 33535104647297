@import 'common.scss';

.api-key-creator {
  @extend .container-box;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .user-info {
    display: flex;
    flex-direction: row;
    gap: 20px;

    & > * {
      flex: 1 1 auto;
    }
  }

  .scopes-info {
  }

  .controls {
    display: flex;
    gap: 16px;
  }
}
