@import 'common.scss';

.metrics-trait-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 1px solid $dark65;
  padding: 0.25rem;

  & > :nth-child(2) {
    width: 350px;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  & > :last-child {
    text-transform: capitalize;
    flex: 1 1 auto;
  }

  &-expandable {
    cursor: pointer;
    margin-left: 10px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}
