.waiting-text {
  overflow: hidden;
  display: inline-block;
  animation: ellipsis-animation 1.5s infinite;

  min-height: 80px;

  @keyframes ellipsis-animation {
    0%,
    20%,
    80%,
    100% {
      width: 0;
    }

    40%,
    60% {
      width: 1em;
    }
  }
}
