@import 'common.scss';

.control-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem 3rem 2.5rem;
  background: $dark90;
  border-radius: 0.5rem;
  gap: 1rem;

  &.exploration-mode {
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    border: 1px solid $cfCyan;
  }

  &__llm-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__assistant {
    textarea {
      max-height: 64px;
    }
  }

  &__exploration-flag {
    margin-left: auto;
  }

  &__actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
  }

  &__form {
    display: flex;
    flex-direction: row;

    & > * {
      flex: 1 1;
    }

    gap: 1rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: start;

    input[type='text'] {
      width: 100%;
    }

    & > .collapsible-content {
      &.expanded {
        background-color: transparent;
      }

      & > .summary > .content {
        flex: 0 1 auto;
      }

      & > .summary > .arrow {
        margin-left: 0.5rem;
      }

      & > .content {
        padding: 2rem 0rem;
      }
    }

    & > .date-time-range-picker > input {
      width: 100%;
    }
  }

  .compare-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
