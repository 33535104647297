@import 'common.scss';

.new-cohort {
  .new-cohort-content {
    @extend .container-box;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .main-cohort-info {
      width: 80%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      & > :first-child {
        flex: 1;
        input {
          width: 90%;
        }
      }

      & > :nth-child(2) {
        flex: 1;
      }
    }
  }
}
