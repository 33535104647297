@import 'common.scss';

.nodes-information {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 10px;

  &__item {
    @extend .card;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .state-legend-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: #5c7bd9; // 536DB9
    font-size: 10px;
    color: white;
    font-weight: bold;
  }

  .state-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
