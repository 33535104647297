@import 'common.scss';
@import 'components/charts/charts.scss';

.cf-line-chart {
  @extend .card;
  @extend .chart;

  &.nested {
    border: unset;
  }

  .titled-chart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: stretch;

    .yaxis-name {
      writing-mode: vertical-lr;
      scale: -1;
      margin: -10px;
      color: $dark50;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .line-chart-controls {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
