@import 'common.scss';

.filter-group-builder {
  &.level_1 {
    background-color: $cardOutline;
    border-radius: 8px;
  }

  &.level_2 {
    background-color: $cardNestedBkgd;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    &-actions {
      display: flex;
      justify-content: flex-end;

      &-operator {
        flex: 1 1 auto;
      }

      &-control {
        align-self: flex-end;
      }
    }

    .filter-group-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      min-height: 30px;
      align-items: center;

      .subject-label {
        text-transform: capitalize;

        &:after {
          content: 's';
        }
      }

      p {
        margin: 0px;
      }

      > :first-child {
        min-width: 100px;
      }

      > :nth-child(1) {
        flex: 1;
        margin-left: 70px;
      }
    }
  }
}
