@import 'common.scss';
@import 'components/charts/charts.scss';

.cf-box-plot-chart {
  @extend .chart;

  .chart-title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
