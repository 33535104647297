@import 'common.scss';

.cf-input {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    input {
      border-radius: $border-radius;
      border: 1px solid $dark65;
      background-color: $dark100;
      min-height: 36px;
      font-size: 14px;
      color: $textPrimary;
      padding-left: 15px;
      width: 100%;

      &.icon {
        padding-left: 30px;
      }

      &.font-sm {
        font-size: 10px;
      }

      &.font-md {
        font-size: 14px;
      }

      &.font-lg {
        font-size: 16px;
      }

      &.font-xl {
        font-size: 18px;
      }
    }

    .input-icon {
      position: absolute;
      left: 10px;
      top: 13px;
    }
  }

  &:focus {
    border: 1px solid $cfCyan;
    box-shadow: 0px 0px 6px 0px rgba($cfCyan, 0.25);
    outline: none;
  }

  .message {
    color: $dark50;
  }
}
