@import 'common.scss';

.scheduling-set {
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 24px;
  }

  .text-md {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .set-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
    position: relative;
    width: fit-content;
    padding-right: 22px;
    display: flex;
    align-items: center;
    gap: 10px;

    &__legend {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

  .row {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    &:last-child {
      margin: 0;
    }
  }

  .small-input {
    width: 80px;
    height: 100%;
    display: flex;
  }

  .checkbox {
    align-self: flex-end;
    padding: 12px 0;
  }
}
