.assistant-wall {
  overflow-y: auto;

  &__spinner {
    width: 100%;
    text-align: center;
  }

  &__content {
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
