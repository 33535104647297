.predicted-observed-lifetime {
  .inrow {
    display: flex;
    // padding: 0 0 40px 0;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    height: 400px;
    padding-top: unset;

    & > * {
      flex: 1;
      border: unset;
      padding-top: unset;
    }
  }

  .predicted-observed-scatter {
    padding-top: 0px;
  }
}
