.cf-scatter-range-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 300px;

  &__plot {
    display: flex;
    justify-content: center;
  }
}
