.monitor-restless-advantage {
  .controls {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    .advantage-field {
      min-width: 200px;
    }
  }
}
