@import 'common.scss';

.model-tag-chip {
  display: flex;
  gap: 10px;
  align-items: center;

  width: min-content;
  color: $cfYellow;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  height: 18px;
  padding: 0px 4px;
  background: rgba(251, 188, 5, 0.15);
  border-radius: 4px;
  border: 1px solid $cfYellow;

  &.ghost {
    border: 1px dashed $cfYellow;
    background-color: unset;
    cursor: pointer;
  }

  .delete-action {
    &:hover {
      font-weight: 600;

      cursor: pointer;
    }
  }
}
