@import 'common.scss';

.hovered-subjects {
  @extend .modal-box;
  padding: 10px;
  width: fit-content;
  max-height: 200px;
  overflow-y: auto;

  &__item {
    cursor: pointer;
  }
}
