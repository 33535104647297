@import './common.scss';

.survival-legend {
  display: flex;
  align-items: center;
  gap: 3px;

  &__item {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  &__leaf {
    background-color: $cfCyan;
  }

  &__cluster {
    background-color: $cfGreen;
  }
}
