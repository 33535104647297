@import 'common.scss';

.message-hint {
  border-radius: 8px;
  border: 1px solid $dark50;
  background-color: $cardBkgd;
  padding: 16px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  &.disabled {
    cursor: unset;
    border: 1px solid $dark70;
    background-color: $dark85;
    color: $dark50;
  }

  &__title {
    font-weight: bold;
    font-family: 'Open Sans';
  }

  &:hover {
    border: 1.5px solid $Primary500;
  }
}
