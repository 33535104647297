@import 'common.scss';

.wizard-steps {
  --size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: $dark90;
  border-radius: 8px;
  padding: 24px;

  a {
    text-decoration: none;
  }

  .step-row {
    display: flex;
    align-items: center;
    gap: 24px;

    .step-name {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: $semibold;

      &.ready {
        color: white;
      }

      &.ongoing {
        color: $cfCyan;
      }

      &.pending {
        color: $dark50;
      }
    }
  }

  .line {
    height: 31px;
    box-shadow: 1px 0px 0px 0px $dark50;
    padding-left: calc((var(--size) / 2) + 1px);
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: $semibold;
    line-height: 20px;

    border-radius: 50%;
    width: var(--size);
    height: var(--size);

    &.ready {
      background-color: $cfCyan;
      border: 1px solid $cfCyan;
      color: black;
    }

    &.ongoing {
      background-color: rgba($color: $cfCyan, $alpha: 0.2);
      border: 1px solid $cfCyan;
      color: $cfCyan;
    }

    &.pending {
      background-color: $dark70;
      border: 1px solid $dark50;
      color: $dark20;
    }
  }
}
