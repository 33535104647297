.markov-states {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .markov-definition {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .budget-container {
    width: 300px;
  }

  .graph-definition {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .tuples {
    .removable-tuple {
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: space-between;
    }
  }

  .boxplots {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}
