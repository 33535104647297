$Blue100: #e1effe;
$Blue200: #c3ddfd;
$Blue300: #a4cafe;
$Blue400: #76a9fa;
$Blue500: #3f83f8;
$Blue600: #1c64f2;
$Blue700: #1a56db;
$Blue800: #1e429f;
$Blue900: #233876;

$Gray100: #f3f4f6;
$Gray200: #e5e7eb;
$Gray300: #d1d5db;
$Gray400: #9ca3af;
$Gray500: #6b7280;
$Gray600: #4b5563;
$Gray700: #374151;
$Gray800: #1f2a37;
$Gray900: #141c26;

$Green100: #def7ec;
$Green200: #bcf0da;
$Green300: #84e1bc;
$Green400: #31c48d;
$Green500: #0e9f6e;
$Green600: #057a55;
$Green700: #046c4e;
$Green800: #03543f;
$Green900: #014737;

$Indigo100: #e5edff;
$Indigo200: #cddbfe;
$Indigo300: #b4c6fc;
$Indigo400: #8da2fb;
$Indigo500: #6875f5;
$Indigo600: #5850ec;
$Indigo700: #5145cd;
$Indigo800: #42389d;
$Indigo900: #362f78;

$Olive100: #d3e3bb;
$Olive200: #bdd59a;
$Olive300: #a7c778;
$Olive400: #91b956;
$Olive500: #78984a;
$Olive600: #5e763e;
$Olive700: #455533;
$Olive800: #2b3327;
$Olive900: #262c22;

$Orange100: #feecdc;
$Orange200: #fcd9bd;
$Orange300: #fdba8c;
$Orange400: #ff8a4c;
$Orange500: #ff5a1f;
$Orange600: #d03801;
$Orange700: #b43403;
$Orange800: #8a2c0d;
$Orange900: #771d1d;

$Pink100: #fce8f3;
$Pink200: #fad1e8;
$Pink300: #f8b4d9;
$Pink400: #f17eb8;
$Pink500: #e74694;
$Pink600: #d61f69;
$Pink700: #bf125d;
$Pink800: #99154b;
$Pink900: #751a3d;

$Primary50: #eef8f6;
$Primary100: #e8f9f7;
$Primary200: #d1f2f0;
$Primary300: #baece8;
$Primary400: #a3e5e1;
$Primary500: #94d5db;
$Primary600: #7aaeb5;
$Primary700: #60878e;
$Primary800: #466068;
$Primary900: #2c3941;

$Purple100: #edebfe;
$Purple200: #dcd7fe;
$Purple300: #cabffd;
$Purple400: #ac94fa;
$Purple500: #9061f9;
$Purple600: #7e3af2;
$Purple700: #6c2bd9;
$Purple800: #5521b5;
$Purple900: #4a1d96;

$Red50: #fdf2f2;
$Red100: #f7dfdb;
$Red200: #f0bfb7;
$Red300: #e89f93;
$Red400: #e17f6f;
$Red500: #d95f4b;
$Red600: #e02424;
$Red700: #c81e1e;
$Red800: #9b1c1c;
$Red900: #771d1d;

$Teal100: #d5f5f6;
$Teal200: #afecef;
$Teal300: #7edce2;
$Teal400: #16bdca;
$Teal500: #0694a2;
$Teal600: #047481;
$Teal700: #036672;
$Teal900: #014451;

$Yellow100: #fdf6b2;
$Yellow200: #fce96a;
$Yellow300: #faca15;
$Yellow400: #e3a008;
$Yellow500: #c27803;
$Yellow600: #9f580a;
$Yellow700: #8e4b10;
$Yellow800: #723b13;
$Yellow900: #633112;
