.payload-viewer {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1.1fr 0.9fr;
  grid-template-rows: 0.1fr 1fr 0.1fr;
  gap: 0px 0px;
  grid-template-areas:
    'name name counter'
    'content content content'
    'arrows arrows arrows';

  & > button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &.single {
    display: block;
  }

  word-wrap: break-word;
  word-break: break-all;

  .name {
    grid-area: name;
    font-size: 16px;
  }

  .content {
    grid-area: content;
    &__name {
      font-weight: 800;
      text-transform: capitalize;
    }
  }

  .counter {
    grid-area: counter;
    font-size: 18px;
    font-family: 'Open Sans';
    text-align: right;
  }

  .args {
    grid-area: content;
    display: flex;
    width: 100%;
    flex-direction: column;

    &__title {
      font-weight: bold;
    }

    &__value {
      padding-left: 25px;
    }
  }
}
