@import 'common.scss';

.cf-summary-box {
  display: flex;
  text-align: center;

  & > * {
    flex: 1 1 auto;

    .value {
      font-size: 26px;
      font-weight: $semibold;
    }

    border-right: 1px solid $dark40;
  }

  & > :last-child {
    border-right: none;
  }
}
