.tree-builder {
  & > .inline {
    display: flex;
    gap: 10px;
  }

  & > :not(:first-child) {
    margin-left: 50px;
  }
}
