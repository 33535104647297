.week-picker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  .week-picker-text {
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    display: flex;
    gap: 5px;
  }

  .week-picker-icon {
    background: url(assets/arrow.svg) no-repeat center;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;

    &.next {
      transform: rotate(180deg);
    }
  }

  .week-picker-icon:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}
