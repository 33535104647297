@import 'common.scss';

.nudge-detail {
  padding: 0 32px 32px;

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    .col {
      display: flex;
      flex-direction: column;
      min-width: 500px;
      gap: 16px;
    }
  }
}

.nudge-detail-editor {
  width: 500px;
  height: 450px;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  &__controls {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
