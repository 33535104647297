.reference-detail {
  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__data {
    padding: 50px;
  }
}
