@import '../../constants.scss';

.visual-map {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px 0px;
  grid-template-areas:
    '. map'
    '. values';

  .steps {
    grid-area: map;
    align-self: end;
    display: flex;

    & > * {
      height: 20px;
      flex: 1 1 auto;
    }

    & > :first-child {
      border-radius: 100px 0 0 100px;
      background: linear-gradient(to right, $threshold_0, $threshold_1);
    }

    & > :nth-child(2) {
      background: linear-gradient(to right, $threshold_1, $threshold_2);
    }

    & > :nth-child(3) {
      background: linear-gradient(to right, $threshold_2, $threshold_3);
    }

    & > :nth-child(4) {
      background: linear-gradient(to right, $threshold_3, $threshold_4);
    }

    & > :last-child {
      border-radius: 0 100px 100px 0;
      background: linear-gradient(to right, $threshold_4, $threshold_5);
    }
  }

  .values {
    grid-area: values;

    display: flex;
    justify-content: space-between;
  }
}
