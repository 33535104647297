.cf-portal {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 100000;
  overflow: auto;

  &.custom {
    top: unset;
  }

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.backdrop {
    backdrop-filter: blur(10px);
  }

  &.fit {
    height: unset;
    width: unset;
  }
}
