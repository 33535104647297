@import 'common.scss';

.churn-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px;

  &__item {
    & > :first-child {
      font-size: 12px;
      text-align: left;
    }

    & > :nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
  }
}
