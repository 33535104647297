@import 'common.scss';

.admin-container {
  @extend .container-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $cardBkgd;

  padding: 24px;
  border-radius: 8px;

  .actions {
    display: flex;
    justify-content: right;
  }
}
