@import 'common.scss';

.cf-select-item {
  padding: 8px 12px;
  background-color: $dark80;
  cursor: pointer;
  display: flex;
  gap: 16px;

  &:hover, &.focused {
    background-color: $dark70;
  }
}
