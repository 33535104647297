.markov-monitoring {
  .field {
    width: 200px;
  }

  &__params {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}
