@import 'common.scss';

.relative-info-box {
  @extend .card;
  position: relative;
  --cardPadding: 24px;
  padding: var(--cardPadding);
  min-width: 130px;
  max-width: 400px;
  height: calc(168px - var(--cardPadding) * 2);

  display: flex;
  flex-direction: column;
  justify-content: center;

  .header {
    text-align: left;

    img {
      width: 13px;
    }
  }

  .extended {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $cfCyan;
    font-size: 14px;
    gap: 3px;

    display: flex;
    gap: 3px;
    align-items: center;

    cursor: pointer;
  }

  .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .value {
      font-size: 36px;

      .unit {
        font-size: smaller;
      }
    }

    .positive {
      color: $ok-color;

      path {
        fill: $ok-color;
      }
    }

    .negative {
      color: $error-color;

      path {
        fill: $error-color;
      }
    }

    .neutral {
      color: $neutral-color;
    }

    .label {
      font-size: 16px;
      color: $dark20;
      font-weight: $semibold;

      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
    }

    .relative-comparison {
      display: flex;
      gap: 6px;
      justify-content: center;
      align-items: center;
      font-weight: $semibold;
      font-size: 14px;
    }
  }
}
