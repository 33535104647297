@import 'common.scss';

.model-definition-parameters {
  &.no-params {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .key-value {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    border-bottom: 1px solid #59595F;
    height: 50px;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    & > * {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 0 10px;
    }

    &.header > *  {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
