@import 'common.scss';

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 100;

  & > :last-child {
    align-self: center;
  }
}

.or-login-with {
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
  margin: 20px 0;
  text-transform: capitalize;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }

  &::before {
    margin-right: 15px;
  }

  &::after {
    margin-left: 15px;
  }
}

.login-panel {
  height: 100%;
  display: grid;
  align-items: center;
  background-color: black;
  overflow: hidden;

  .logo {
    width: 300px;
    justify-self: center;
    margin-bottom: 70px;
  }

  .login-button {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-self: center;
    z-index: 1000000000;

    .error {
      width: 300px;
      text-align: center;
      color: $error-color;
    }
  }

  .top-left-gradient {
    position: absolute;
    height: 80vh;
    width: 80vh;
    top: -40vh;
    left: -40vh;
    background: rgba(152, 222, 217, 0.3);
    filter: blur(300px);
    transform: rotate(-180deg);
  }

  .bottom-right-gradient {
    position: absolute;
    border-radius: 100%;
    bottom: -40vh;
    right: -20vh;
    height: 80vh;
    width: 80vh;

    background: rgba(254, 194, 74, 0.3);
    filter: blur(300px);
    transform: rotate(-130.64deg);
  }

  .circle-pattern {
    position: absolute;

    img {
      position: relative;
      left: 60vw;
      top: 60vh;
    }
  }

  .hex-pattern {
    position: absolute;

    img {
      right: 65vh;
      position: relative;
      width: 80%;
      top: -25vh;
    }
  }
}
