.microsoft-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #3c4043;
  min-width: 200px;
  width: 300px;
  border: 1px solid #dadce0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  height: 38px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.microsoft-login-button:hover {
  background-color: #e1e1e1;
}

.microsoft-login-button img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
