@import 'common.scss';

.list-of-projects {
  .project-detail {
    padding: 36px;

    .section {
      display: flex;
      gap: 8px;
    }

    .blog {
      padding-right: 32px;
      text-align: left;
      min-width: 240px;
    }
  }
  .category {
    font-weight: $semibold;
    font-size: 14px;
    color: white;
    line-height: 26px;
    margin-bottom: 8px;
    width: fit-content;

    & > span {
      color: $dark60;
      font-weight: 400;
    }
  }
  .controls {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
