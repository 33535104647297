@import 'common.scss';

.assistant-splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 10%;
  height: 100%;
  color: $textPrimary;
  padding: 16px;

  & > .title {
    font-size: 22px;
    font-weight: $semibold;

    &.embedded {
      font-size: 16px;
    }
  }

  & > .subtitle {
    font-size: 14px;
    width: 40%;

    &.embedded {
      width: 80%;
    }
  }
}
