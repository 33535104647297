@import 'common.scss';

.ingest-history {
  .container-parent {
    width: 100%;
    display: grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px 10px;
    grid-template-areas: 'history detail';
    background-color: $cardBkgd;
    max-height: 600px;

    .load-wrapper {
      grid-area: history;
      border: 1px solid $table-border-color;
      overflow-y: auto;
    }

    .container-child-right {
      grid-area: detail;
      border: 1px solid $table-border-color;

      overflow-y: auto;
    }

    .container-code-preview {
      grid-area: detail;
      padding: 20px;
      text-align: left;
      line-height: 1.7;
      background-color: $card-bg;
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
      overflow-y: scroll;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $table-bg;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $table-scroll-color;
      border-radius: 20px;
    }
  }
}
