@import 'common.scss';

.model-definition-features {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .field-value {
    text-align: left;

    span {
      font-weight: 600;
      margin-right: 5px;
    }
  }

  .title {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .selected-items {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .trait-with-info {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
