.sampling {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .reduced {
    width: 30%;
    min-width: 300px;
  }

  .action-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 8px;
    padding-right: 24px;

    & > :first-child {
      flex: 1 1 auto;
    }
  }
}
