@import 'common.scss';

.cf-titled-component {
  font-family: 'Open Sans';
  text-align: left;

  &.inline {
    display: flex;
    justify-content: space-between;
  }

  & > :first-child {
    margin-bottom: 5px;
  }

  .title-size {
    font-weight: $semibold;
    display: flex;
    align-items: center;
    gap: 2px;

    &.extra {
      font-size: 18px;
    }

    &.big {
      font-size: 16px;
    }

    &.small {
      font-size: 14px;
    }

    & > .subtitle {
      font-size: smaller;
      color: $dark40;
    }
  }
}
