.dynamic-chart {
  &__controls {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;

    & > * {
      min-width: 200px;
    }
  }

  & > .cf-line-chart {
    border: unset;
    padding: unset;
  }
}
