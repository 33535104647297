@import 'common.scss';

.collapsible-content {
  @mixin collapsible-content-container {
    border-radius: 8px;
    background-color: $cardNestedBkgd;
  }

  &.expanded {
    &.nested {
      @include collapsible-content-container;
    }
  }

  .summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $textPrimary;
    padding-right: 20px;
    cursor: pointer;

    &.nested {
      @include collapsible-content-container;
    }

    .content {
      flex: 1 1 auto;
    }

    .arrow {
      flex: 0 1 auto;
    }
  }

  & > .content {
    padding: 15px 0px;

    &.nested {
      padding: 15px 25px;
    }
  }
}
