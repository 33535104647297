@import 'common.scss';

.android-nudge-preview {
  height: 100%;
  border-radius: 8px;
  padding: 32px 32px 0px 32px;
  box-sizing: border-box;
  width: 415px;
  height: 300px;

  & > .title {
    text-align: center;
    color: $dark40;
    font-size: 14px;
    font-style: italic;
    line-height: 40px; /* 142.857% */
  }

  & > .phone {
    background-color: $cardBkgd;

    height: 100%;
    border-radius: 25px 25px 0 0;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 7px 7px 0 7px;
    box-sizing: border-box;
    border: 1px solid #8eadc1;
    border-bottom: none;

    & > .screen {
      background-color: $dark80;
      background-image: url(images/android.png);
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      height: 100%;
      border-radius: 20px 20px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .header {
        position: relative;
        width: 50%;
        display: flex;
        height: 30px;
        justify-items: center;
        align-items: center;
        justify-content: center;

        .small {
          min-width: 16px;
          border-radius: 50%;
          position: absolute;
          right: 30px;
        }

        .big {
          width: 40%;
          border-radius: 5px;
        }
      }

      & > .notification {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 85%;
        font-size: 15px;
        color: black;
        margin-top: 35px;
        padding: 14px;

        background: rgba(245, 245, 245, 0.6);
        backdrop-filter: blur(16.600963592529297px);

        & > .header {
          font-size: 12px;
          color: $cfBlue;
          display: flex;
          align-items: center;
          gap: 5px;

          & > .time {
            color: #595959;
            flex: 1 1 auto;
          }
        }

        & > .alert-icon {
          padding-right: 5px;
        }

        & > .title {
          font-weight: 400;
          color: var(--Settings-first-title, #222);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
        }

        & > .text {
          color: var(--Secondary-color, #686868);
          font-family: 'Roboto';
          font-size: 13px;
        }
      }
    }
  }
}
