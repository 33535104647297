@import 'common.scss';

.prompt_hints {
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: left;

  &__header {
    display: flex;
    justify-content: space-between;

    &__switcher {
      display: flex;
      gap: 8px;
      align-items: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
  }

  &__prompts {
    display: flex;
    gap: 10px;
    justify-content: center;

    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
