@import 'common.scss';

.edit-project-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .input {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .controls {
    grid-area: controls;
    display: flex;
    gap: 10px;
  }
}
