@import 'common.scss';

.cohort-detail {
  padding: 24px;

  .cohort-detail-info {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'description creation-date'
      'description created-by';

    .creation-date {
      grid-area: creation-date;
    }

    .description {
      grid-area: description;
    }

    .created-by {
      grid-area: created-by;
    }
  }

  // separate the navigation bar
  & > :first-child {
    margin-bottom: 30px;
  }

  .description {
    padding: 10px;
  }

  .field-title {
    font-weight: $semibold;
    margin-right: 5px;
  }

  .cohort-user {
    padding: 10px 4px;
  }
}
