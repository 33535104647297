@import 'common.scss';

.list-of-interventions {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .intervention-container {
    @extend .card;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    @extend .main-title;
  }

  .no-data-desc {
    color: $dark50;
    font-size: 14px;
    text-align: center;
  }

  &-extended-controls {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin: 24px 0;
    padding: 0 32px;
  }

  &-error-icon {
    margin-left: 10px;
    color: rgba(214, 66, 75, 1);
  }

  .intv-title {
    display: flex;
    gap: 5px;
  }
}
