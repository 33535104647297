@import 'common.scss';
@import 'components/charts/charts.scss';

.cf-scatter-chart {
  @extend .card;
  @extend .chart;

  .controls {
    cursor: pointer;
  }

  &.nested {
    border: unset;
  }

  & > .chart-header {
    position: relative;
  }

  & > .chart-header.overlap {
    margin-bottom: -60px;
    z-index: 10000;
  }
}

.expandable-scatter-plot {
  // width: 70%;

  min-width: 500px;
  min-height: 500px;

  & > .echart-instance {
    height: 500px !important;
  }
}
