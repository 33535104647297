.tuple-creator {
  display: flex;
  gap: 10px;
  width: 90%;
  flex-wrap: wrap;
  flex: 1 1 auto;

  & > :first-child {
    flex: 1 1 auto;
  }

  & > :nth-child(2) {
    min-width: 100px;
  }

  .input-number {
    width: 70px;
  }
}
