.load-wrapper {
  position: relative;

  .load-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .fade {
    backdrop-filter: blur(10px);
  }

  .solid {
    background: #262833;
  }
}
