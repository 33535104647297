$gray-900: #fcfcfd;
$gray60: #65656b;
$gray50: #88888d;
$gray40: #717176;
$primary50: #53389e;
$light: #fff;

$darkmode_4: #1b1b24;
$darkmode_6: #202029;
$darkmode_8: #25252d;
$darkmode_10: #2a2a32;
$darkmode_14: #33333b;

$dark20: #d0d0d1;
$dark30: #b8b8bb;
$dark40: #a0a0a4;
$dark50: #88888d;
$dark60: #65656b;
$dark65: #59595f;
$dark70: #46464d;
$dark85: #25252d;
$dark80: #2a2a32;
$dark90: #1d1d27;
$dark95: #1b1b24;
$dark100: #12121b;

$cfCyan: #94d5db;
$cfCyan_disabled: rgba($cfCyan, 0.3);

$darkCfCyan: #2c3941;
$darkCfCyan20: #7aaeb5;
$darkCfCyan40: #60878e;
$darkCfCyan60: #466068;

$cfYellow: #fbbc05;
$cfDarkYellow: #413417;
$cfBlue: #1611f1;
$cfGreen: #24cba3;
$cfViolet: #5a58ec;
$cfRed: #f96f66;
$cfDarkCyan: #2c3941;

$cardBkgd: #181822;
$cardNestedBkgd: #262833;

$cardOutline: #1e212b;
$cardTitleColor: white;

$base-color: $dark100;
$header-bg-color: #282c34;
$front-base-color: #202029;
$secondary-font-color: #667085;
$label-font-color: #ffffffbf;

$title-color: #bbbbbd;

$textPrimary: $dark20;

$primary: $cfCyan;

$background-color: radial-gradient(
    38.81% 98.49% at 24.2% 11.26%,
    rgba(140, 223, 217, 0.14) 0%,
    rgba(140, 223, 217, 0) 100%
  ),
  radial-gradient(29.41% 56.29% at 92.6% 87.38%, rgba(251, 188, 5, 0.12) 9.47%, rgba(251, 188, 5, 0) 100%), #12121b;

// cards
$card-bg: $darkmode_4;

// table
$table-bg: $cardBkgd;
$table-header: $cardBkgd;
$table-border-color: $gray60;
$table-selected-row: $darkmode_8;
$table-row-hover: $darkmode_8;
$table-text-color: $gray-900;
$table-header-color: $gray-900;
$table-title-color: white;
$table-scroll-color: $gray60;

$portal-bg: $base-color;

// buttons
$button-bg-default: $darkmode_4;
$button-bg-hover: $darkmode_8;
$button-bg-active: $darkmode_14;
$button-bg-disabled: $darkmode_8;
$button-bg-focused: $darkmode_4;

$button-fg-default: white;
$button-fg-hover: white;
$button-fg-active: white;
$button-fg-disabled: $gray60;
$button-fg-focused: white;

$button-border-color: $gray60;

// input
$input-bg: $darkmode_4;
$input-border-color: $gray60;
$input-outline: $dark65;

$semibold: 600;

$dashboard-item-font-color: #bebec1;
$secondary-font-color: #88888d;

$border-radius: 6px;

$nav-item-inactive: $dark40;
$nav-item-active: white;

$ok-color: #039874;

$error-color: #d6424b;
$neutral-color: $dark50;

// cards
$card-outline: #1e212b; // TODO: use a color name (not defined)
$element-outline: #46464d;

// rounded borders
$rounded-xl: 16px;
$rounded-lg: 8px;
$rounded-md: 6px;
$rounded: 4px;

// spacing (paddings, gaps)
$xs4: 1px;
$xs3: 2px;
$xs2: 4px;
$xs: 6px;

.card {
  background-color: $cardBkgd;
  border: 1px solid $cardOutline;
  border-radius: 8px;
  padding: 32px 40px;
  text-align: left;
}

.container-box {
  background-color: $dark90;
  padding: 24px;
  border-radius: 8px;
}

.modal-box {
  background-color: $dark90;
  border-radius: 12px;
  border: 1px solid $dark60;
}

.main-title {
  margin-bottom: 30px;
  color: $dark30;
  text-align: left;
  font-size: 20px;
  font-weight: $semibold;
  text-transform: uppercase;
}

.subhead {
  font-weight: $semibold;
  font-size: 18px;
  text-align: left;
}

.total-text {
  font-weight: $semibold;
  font-size: 16px;
  text-align: left;
}
