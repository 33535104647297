@import 'common.scss';

.node-editor {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .target-editor {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__controls {
    display: flex;
    gap: 5px;

    & > :last-child {
      align-self: flex-end;
    }
  }

  &__header {
    font-size: 14px;
    font-weight: $semibold;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
