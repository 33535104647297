@import 'common.scss';

.tree-viewer {
  padding: 12px;
  border-radius: 8px;
  background-color: $cardBkgd;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .filter-group-builder.level_2 {
    margin-left: 50px;
    border-radius: 8px;
  }

  .filters .filter-group-row .query-expression.no-padding {
    margin-left: 0;
  }
}
