.selected-cohort {
  display: flex;
  flex-direction: row;
  gap: 32px;

  & > :first-child {
    width: 400px;
    flex-shrink: 0;
  }
}
