.kpi-matrix {
  grid-area: cards;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .cards-by-type {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .relative-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      & > * {
        flex: 1 1 10%;

        @media (max-width: 1200px) {
          flex: 1 1 25%;
        }
      }
    }

    .data-info-boxes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;

      & > * {
        flex: 1 1 10%;

        @media (max-width: 1200px) {
          flex: 1 1 45%;
        }

        @media (max-width: 1000px) {
          flex: 1 1 35%;
        }
      }
    }
  }
}
