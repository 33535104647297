@import 'common.scss';

.date-range-menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: $dark90;
  border-radius: 0.5rem;
  padding: 1.5rem;
  gap: 1.5rem;
  z-index: 99;

  .menu-months-container {
    display: flex;
    flex-direction: row;
  }

  .menu-times-container {
    display: flex;
    flex-direction: column;
    padding: 0 5rem;
    gap: 1rem;
  }

  .menu-time-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .menu-timezone-picker-container {
    align-self: center;
    width: 100%;
  }

  .menu-time-picker {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .menu-footer {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    justify-content: flex-end;
  }
}
