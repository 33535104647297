@import 'common.scss';

.monitoring {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    .label {
      color: $secondary-font-color;
    }

    & > .spacer {
      flex: 1 1 auto;
    }

    .chart-level-label {
      font-size: 14px;
      font-weight: 500;
      color: $label-font-color;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .title {
    @extend .main-title;
  }

  .no-data-desc {
    color: $dark50;
    font-size: 14px;
    text-align: center;
  }

}
