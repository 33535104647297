@import 'common.scss';

.selected-tags {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  &-label {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;

  }

  &-value {
    padding: 4px 6px;
    border-radius: 4px;
    color: $dark100;
    background-color: var(--tag-color);
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  &-cross {
    margin-left: 6px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  &.sticky {
    position: sticky;
    top: -40px;
    padding: 24px 16px;
    background-color: #181822;
    border: 1px solid #1e212b;
    border-radius: 0 0px 8px 8px;
    z-index: 10000;
  }
}
