@import 'common.scss';

.cohort-extended-info {
  text-align: left;

  & > .counter {
    font-size: 16px;
    font-weight: $semibold;
  }
}
