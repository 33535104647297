@import 'common.scss';

.list-of-nudges {
  .nudge-container {
    @extend .card;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .expanded-summary {
    display: flex;
    align-items: center;
    padding: 10px;

    & > :last-child {
      margin-left: auto;
      padding-right: 5px;
    }
  }

  .title {
    @extend .main-title;
  }

  .nudges-extended {
    margin-top: 19px;
    display: flex;
    flex-direction: column;

    &-controls {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding-right: 24px;
      min-width: 200px;
    }
  }

  .tags {
    display: flex;
    gap: 10px;
    align-items: center;

    .tag {
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      border-radius: 99px;
    }
  }
}
