@import 'common.scss';

.query-expression {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-start;
  padding: 5px;

  .field {
    flex: 1 0 100px;
  }

  .operator {
    flex: 0 0 150px;
    max-width: 50px;
  }

  .value {
    flex: 1;
  }

  .expressionOperator {
    min-width: 80px;

    &.hidden {
      visibility: hidden;
    }
  }
}

.cf-portal .extended-filter-values {
  background-color: $cardNestedBkgd;
  width: 80%;
  height: 80%;
  overflow-y: auto;
}
