@import 'common.scss';

.list-of-cohorts {
  .cohort-container {
    @extend .card;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .filters {
        display: flex;
        gap: 16px;

        .cf-select {
          min-width: 200px;
        }
      }

      .inline {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .subjects-count {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .counter-icon {
    flex: 0 1 auto;

    svg {
      width: 20px;
    }
  }

  .tags {
    display: flex;
  }

  .tag {
    width: fit-content;
  }

  .no-data {
    display: flex;
    justify-content: center;
    padding: 24px;
  }
}
