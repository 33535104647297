@import 'common.scss';

.filter-builder {
  display: grid;
  grid-template-columns: 0.5fr 0.15fr 1.4fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'trait operator value';
  gap: 10px;

  &.extended {
    grid-template-columns: 0.5fr 0.3fr 0.15fr 1fr;
  }

  & > :nth-child(1) {
    grid-area: trait;
    min-width: 425px;
  }

  & > :nth-child(2) {
    grid-area: operator;
    min-width: 75px;
  }

  & > :nth-child(3) {
    grid-area: value;
  }

  .cf-select-input {
    height: 100%;
  }
}
