@import 'common.scss';

.user-detail-container {
  .section-title {
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
  }

  .controls {
    grid-area: controls;
    display: flex;
    gap: 10px;
  }

  .user-detail-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
