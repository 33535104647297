@import 'common.scss';
@import 'components/charts/charts.scss';

.cf-step-chart {
  @extend .card;
  @extend .chart;

  .chart-header {
    display: flex;
    gap: 10px;
    justify-content: center;

    .title {
      display: flex;
      gap: 10px;
      flex: 1 1 auto;
      align-items: center;
    }

    .controls {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .titled-chart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: stretch;

    .yaxis-name {
      writing-mode: vertical-lr;
      scale: -1;
      margin: -10px;
      color: $dark50;
      font-size: 14px;
      white-space: nowrap;
    }
  }
}
