@import 'common.scss';

.search-info {
  max-width: 600px;
  max-height: 600px;
  overflow-y: auto;

  .search-info-chunks {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.upload-file-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__list {
    @extend .card;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 2rem 2.5rem 2rem 2.5rem;
    background: $dark90;
    border-radius: 0.5rem;

    .editable-file-input {
      width: 100%;
      display: flex;
      gap: 10px;

      & > :first-child {
        flex: 1 1 auto;
      }
    }

    .search-form {
      display: flex;
      gap: 5px;

      & :first-child {
        flex: 1 1 auto;
      }
    }
  }

  .document-actions {
    display: flex;
    gap: 5px;
  }
}
