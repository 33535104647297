@import 'common.scss';

.data-info-box {
  @extend .card;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 168px;
  padding: 24px;
  gap: 15px;
  width: min-content;

  .extended {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $cfCyan;
    font-size: 14px;
    gap: 3px;

    display: flex;
    gap: 3px;
    align-items: center;

    cursor: pointer;
  }
  .title {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;

    text-align: left;
    font-size: 16px;
    color: $dark20;
    font-weight: $semibold;

    .unit-title {
      font-size: 12px;
    }
  }

  .values {
    flex: 1 1 auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > * {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-right: 1px solid $dark65;
      flex: 1;
      padding: 0 10px;
    }

    & > :last-child {
      border-right: none;
    }
  }

  .label {
    font-size: 14px;
    color: $secondary-font-color;
    white-space: nowrap;
  }

  .value {
    font-size: 30px;

    &.clickable {
      cursor: pointer;
    }
  }

  .unit {
    font-size: 16px;
  }
}
