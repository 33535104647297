@import '../../common.scss';

.cf-text {
  border-radius: $border-radius;
  border: 0.01em solid $element-outline;
  background-color: $card-bg;
  height: 2em;
  font-size: 20px;
  color: $textPrimary;
  padding-left: 15px;
  display: flex;
  align-items: center;
}
