@import 'common.scss';

.sample-viewer-container {
  @extend .card;
  border: unset;
}

.sample-viewer {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 12px;

  .sample-item {
    text-align: center;
    padding: 10px 4px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid $dark40;

    &.clickable {
      cursor: pointer;
    }

    &.selected {
      text-decoration: line-through;
    }
  }
}
