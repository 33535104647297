@import 'common.scss';

.confirmation-panel {
  @extend .modal-box;

  width: 400px;
  height: 150px;

  display: grid;
  grid-template-columns: 0.3fr 1.3fr;
  grid-template-rows: 1.6fr 0.4fr;
  gap: 0px 0px;
  grid-template-areas:
    'logo main'
    'controls controls';

  padding: 24px;
  align-items: flex-start;

  .logo {
    grid-area: logo;
    color: $error-color;

    .logo-wrapper {
      border: 2px solid $error-color;
      padding: 5px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
    }
  }

  .main {
    grid-area: main;
    font-size: large;

    .question {
      color: $dark40;
      font-size: 14px;
    }

    .title {
      font-weight: $semibold;
      margin-bottom: 8px;
    }
  }

  .controls {
    grid-area: controls;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
