@import 'common.scss';

.apikeymanagement {
  @extend .card;

  .controls {
    display: flex;

    .nav-adjustments {
      margin-top: 10px;
    }

    & > .spacer {
      flex: 1 1 auto;
    }
  }
}
