@import 'common.scss';

.cf-select {
  .custom-value {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    align-items: center;
    text-align: start;
  }
}
