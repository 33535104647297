@import 'common.scss';

$separation: 8px;

.cf-nav-list-links {
  font-size: 18px;
  display: flex;
  flex-direction: row;

  .nav-list-item {
    color: $nav-item-inactive;
    margin-right: 50px;
    cursor: pointer;
    text-transform: capitalize;

    &.selected {
      color: $nav-item-active;
      font-weight: $semibold;
      opacity: 1;
    }

    &.selected-bottom-border {
      border-bottom: 4px solid $cfCyan;
    }

    &.underline-selected {
      padding: 1px;
      border-bottom: 4px solid $cfCyan;
      padding-bottom: $separation;
    }
  }

  & > .spacer {
    flex: 1 1 auto;
  }

  & > .actions {
    text-align: right;
  }

  &.separator {
    margin-bottom: 24px;
    border-bottom: 1px solid gray;
    text-align: left;
  }
}
