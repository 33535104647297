@import 'common.scss';

.invite-user-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .section-title {
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
  }

  .list-of-projects {
    padding-left: 20px;
  }

  .attributes {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 40px;

    & > :first-child {
      width: 100px;
    }
  }

  .controls {
    grid-area: controls;
    display: flex;
    gap: 16px;
  }
}
