@import 'common.scss';

.ab-testing {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .extendable-viewer {
    background-color: $cardNestedBkgd;
    padding: 16px 24px;
    border-radius: 8px;
  }

  .input-ratio {
    min-width: fit-content;
  }

  .ratios {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .inline {
      display: flex;
      gap: 5px;

      span {
        font-size: 26px;
      }
    }
  }

  .error-message {
    background-color: $error-color;
    padding: 5px;
    border-radius: 3px;
  }
}
