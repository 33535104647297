@import 'common.scss';

.assistant-message {
  text-align: left;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
  color: $textPrimary;

  &.question {
    background-color: $dark70;
    border: 1px solid $dark80;
  }

  &.validation-request,
  &.answer {
    background-color: $cardBkgd;
    border: 1px solid $cardOutline;
  }

  &.error {
    background-color: #291717;
    border: 1px solid $error-color;
  }

  .content {
    display: flex;

    gap: 10px;
    //padding-left: 15px;
    flex-direction: column;

    .bold {
      font-weight: bold;
    }

    &__footer {
      display: flex;
      justify-content: space-around;
      font-size: 12px;
      gap: 5px;

      .follow-up {
        cursor: pointer;

        :hover {
          text-decoration: underline;
        }
      }

      .timestamp {
        margin-left: auto;
      }
    }
  }

  .title {
    font-weight: $semibold;
    font-size: 16px;
    display: flex;
    gap: 10px;
    align-items: center;

    &__actions {
      margin-left: auto;
      display: flex;
      gap: 5px;
      align-items: center;

      cursor: pointer;
    }

    &__chart-enabler {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 12px;
      margin-left: auto;
    }
  }
}
