@import 'common.scss';

.cf-titled-section {
  @extend .card;

  &.nested {
    background-color: $cardNestedBkgd;
  }

  > .content {
    padding-top: 24px;
  }

  > header {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    &.underlined {
      border-bottom: 1px solid $dark70;
    }

    .header-row {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .actions {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      font-size: unset;
      gap: 10px;
    }

    .title {
      //flex: 1 1 auto;
      text-transform: capitalize;
      font-weight: $semibold;
      color: $cardTitleColor;
      margin-bottom: 4px;
      font-size: 18px;
      display: flex;
      gap: 10px;
      align-items: center;

      &__close {
        cursor: pointer;
      }

      &__sub {
        font-size: 0.75em;
        font-weight: normal;
      }

      &.big {
        font-size: 18px;
      }

      &.small {
        font-size: 14px;
      }
    }

    .subtitle {
      color: $dark20;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
