@import 'common.scss';

.model-definition-cohort {
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: left;

  .title {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .type {
    display: flex;
    gap: 8px;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 4px;
  }

  .ratios {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}
