@import 'common.scss';

.backfill-modal-wrapper {
  @extend .modal-box;
  width: 368px;
  padding: 1.5rem;
  // background-color: $cardBkgd;
  // border: 1px solid $dark65;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 10;

  .backfill-actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    & > * {
      flex-grow: 1;
    }
  }
}
