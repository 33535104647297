@import 'common.scss';

.assistant {
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  & > :first-child {
    flex: 1 1 auto;

    margin: 0px -16px;
  }

  &.embedded {
    padding: 0px 16px;
    box-sizing: border-box;
    overflow-y: hidden;
  }

  .assistant-text-area {
    height: unset;
  }

  .controls-container {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 600;

    & > :first-child {
      margin-right: auto;
    }

    &__highlight {
      color: $cfCyan;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
