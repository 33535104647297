.markov-subject-viewer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .input {
    display: flex;
    gap: 10px;

    & > :first-child {
      flex: 1 1 auto;
    }
  }

  .graphs {
    display: flex;
    gap: 20px;

    & > * {
      width: 50%;
    }
  }
}
