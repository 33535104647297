@import 'common.scss';

.skeleton {
  display: flex;
  margin: 0 auto;
  background: $dark80;
  background-image: linear-gradient(to right, $dark80 0%, $dark70 50%, $dark80 100%);
  background-repeat: no-repeat;
  background-size: 100px 100px;
  border-radius: 4px;
  
  animation-duration: 3s;
  animation-fill-mode: forwards; 
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -100px 0;
  }
  
  100% {
    background-position: 100px 0; 
  }
}
