@import 'common.scss';

.new-nudge-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.new-nudge {
  @extend .card;

  &.new-nudge-recommend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.1fr 1fr 0.1fr;
    gap: 24px;
    grid-template-areas:
      'header header'
      'config message'
      'controls controls';
  }

  &.new-nudge-message {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.05fr 1fr 1fr 0.1fr;
    gap: 24px;
    grid-template-areas:
      'header header'
      'config message '
      'viewer viewer'
      'controls controls';
  }

  &__header {
    grid-area: header;

    font-size: 22px;
    font-weight: $semibold;
  }

  &__viewer {
    @extend .nudge-box;

    grid-area: viewer;
    padding-bottom: 0px;
    .content {
      display: flex;
      gap: 30px;
      justify-content: center;
    }
  }

  .nudge-box {
    padding: 16px;
    background-color: $cardNestedBkgd;
    border-radius: 8px;
  }

  &__config {
    @extend .nudge-box;

    grid-area: config;

    .full-height {
      height: 95%;
    }
  }

  &__message {
    @extend .nudge-box;

    grid-area: message;

    .full-height {
      height: 95%;
    }
  }

  &__controls {
    grid-area: controls;

    margin-top: 20px; // hack due preview oversize in the bottom
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }
}
