.intervention {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .control {
    align-self: flex-end;
  }
}

.interventionSection {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
  gap: 10px;
}
