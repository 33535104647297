@import 'common.scss';

.pagination {
  .pagination-item {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 16px;
    background-color: transparent;
    border-radius: 8px;
    outline: none;
    border: none;
    color: $dark100;
    list-style-type: none;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
  }

  .item-focused {
    background-color: $dark20;
    color: black !important;
  }
  .item-disabled {
    color: $dark40 !important;
    pointer-events: none;
  }

  .item-invisible {
    visibility: hidden;
    pointer-events: none;
  }

  display: flex;
  justify-content: right;
  margin-block: auto;

  & > * {
    border-bottom: 1px solid $gray60;
  }

  & > :last-child {
    border-bottom: none;
  }
}
