@import 'common.scss';

.paginated-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .paginated-content {
    display: flex;
    flex-direction: column;

    .separator {
      & > * {
        border-bottom: 1px solid $gray60;
      }
    }

    & > :last-child {
      border-bottom: none;
    }
  }

  .paginated-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .paginated-size {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
