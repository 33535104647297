@import 'common.scss';

.trait-info-per-subject {
  border: 1px solid $dark65;
  background-color: $dark80;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;

  &__header {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    & > .title {
      font-size: 14px;
      font-weight: bold;
      justify-self: flex-start;
      flex: 1 1 auto;
    }
  }

  &__footer {
    display: flex;
    gap: 10px;

    align-items: center;
    justify-content: space-between;
  }

  .trait-value-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 300px;
    overflow-y: auto;

    .warning-message {
      align-self: center;
      align-content: center;
      height: 100%;
    }
  }
}
