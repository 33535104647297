@import '../../common.scss';

.cf-message {
  border-radius: 99px;
  padding: 20px;
  text-align: left;

  &.neutral {
    background-color: $primary50;
  }

  &.error {
    background-color: red;
  }
}
