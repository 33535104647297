@import 'common.scss';

:root {
  --form-control-color: white;
  --form-control-disabled: #959495;
}

.cf-checkbox {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  align-content: center;

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
  /*
      input[type="checkbox"]:focus {
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
      }*/

  input[type='checkbox']:disabled {
    --form-control-color: var(--form-control-disabled);

    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  input[type='checkbox']:focus {
    border: 1px solid $cfCyan;
    box-shadow: 0px 0px 6px 0px rgba($cfCyan, .25);
    outline: none;
  }

  &.secondary {
    input[type='checkbox'] {
      border: 1px solid $dark40;
      background-color: $cardBkgd;

      &:checked {
        background-color: $dark40;

        
      }

      &::before {
        background-color: $cardBkgd;
        box-shadow: none;
      }
    }
  }
}
