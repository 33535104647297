@import 'common.scss';

.trait-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 1px solid $dark65;
  padding: 0.25rem;

  & > :first-child {
    width: 50px;
  }

  & > :nth-child(2) {
    width: 400px;
  }

  & > :nth-child(3) {
    width: 200px;
  }

  & > :nth-child(4) {
    width: 100%;
    text-transform: capitalize;
  }

  &-expandable {
    margin-left: 10px;
  }
}
