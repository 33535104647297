@import 'common.scss';

.new-project-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.6fr 1fr 0.1fr;
  gap: 24px;
  grid-template-areas:
    'name subject .'
    'description description description'
    'controls controls controls';
}

.controls {
  grid-area: controls;
  display: flex;
  gap: 10px;
}

.description {
  grid-area: description;
}

.name {
  grid-area: name;
}

.subject {
  grid-area: subject;
}
