.cf-roles-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .list-of-projects {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .list-of-roles {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .project-roles {
    display: flex;
    & > :first-child {
      width: 150px;
      padding: 5px;
      font-weight: bold;
    }
  }

  .attributes {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 20px;
  }
}
