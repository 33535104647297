@import 'common.scss';

.catalog-history {
  overflow: auto;
  background-color: $dark95;
  padding: 32px 32px 300px 32px;

  .search-catalog-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    & > :first-child {
      flex: 1;
    }

    & > :nth-child(2) {
      flex: 1;
    }

    .searchCatalogButton {
      display: inline-block;
      align-self: flex-end;
    }

  }

  .error-message{
    text-transform: capitalize;
  }

  .container-parent {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;

    .container-child-left {
      width: 50%;
      border: 1px solid $table-border-color;
      margin-right: 0.5em;
    }

    .container-child-right {
      width: 50%;
      margin-left: 0.5em;
    }

    .container-code-preview {
      border: 1px solid $table-border-color;
      padding: 20px;
      margin: 0 auto;
      text-align: left;
      line-height: 1.7;
      height: 560px;
      background-color: $card-bg;
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
      overflow-y: scroll;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $table-bg;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $table-scroll-color;
      border-radius: 20px;
    }
  }

}
