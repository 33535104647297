.model-ml-traits {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .user-input {
    display: flex;
    gap: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }
}
