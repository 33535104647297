@import 'common.scss';

.traits {
  .title {
    @extend .main-title;
  }

  .traits-content {
    @extend .card;

    .filters {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .subject-selector {
      min-width: 200px;
    }
  }
}
