@import 'common.scss';

.filter-controls {
  @extend .container-box;
  //background-color: $dark90;

  display: flex;
  justify-content: space-between;

  gap: 10px;

  & > * {
    width: 100%;
  }

  & input {
    width: 100%;
  }
}
