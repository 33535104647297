@import 'common.scss';

.new-org-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    & > * {
      width: 100%;
    }
  }

  .controls {
    grid-area: controls;
    display: flex;
    gap: 10px;
  }
}
