@import 'common.scss';

.intervention-detail {
  .content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 40px;
    grid-auto-flow: column;

    .description {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }
}
