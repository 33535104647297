@import 'common.scss';

.cf-tooltip {
  position: relative;
  padding: 2px;
}

.cf-tooltip-description {
  position: absolute;
  padding: 0.3rem;
  font-size: 12px;
  width: max-content;
  border: 1px solid $dark65;
  background-color: $dark80;
  border-radius: 5px;
  z-index: 10000;

  &.keep-styles {
    border: unset;
    background-color: unset;
    padding: unset;
  }

  &.top {
    top: -15px;
    left: -10px;
  }
}
