@import 'common.scss';

.project-picker {
  padding: calc(100% / 8);
  .card-item {
    box-sizing: border-box;
    background-color: $darkmode_4;
    border-radius: $border-radius;
    width: 230px;
    min-height: 138px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $dark30;
    font-weight: 600;
    padding: 24px;

    & > .title {
      font-size: 16px;
      line-height: 22px;
      color: white;
      margin-bottom: 12px;
    }
    & > .category {
      font-size: 14px;
      margin-bottom: 8px;
      text-align: left;
      line-height: 26px;
      & > span {
        color: $dark60;
      }
    }
  }
}
