@import 'common.scss';

.analytics {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .secondary-controls {
    display: flex;
    gap: 10px;

    & > :first-child {
      flex: 1 1 auto;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .chart-loading {
    display: flex;
    height: 330px;
    justify-content: center;
    align-items: center;
  }

  .empty-chart-label {
    font-size: 18px;
    margin-top: 50px;
  }

  .no-data {
    display: flex;
    height: 330px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
}
