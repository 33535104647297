@import 'common.scss';

.key-value-pair {
  color: $dark20;
  display: flex;
  gap: 8px;
  align-items: flex-start;

  &.force-breakline {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .key {
    font-weight: $semibold;
    color: white;
  }
}
