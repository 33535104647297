.cf-tabs {
  display: flex;
  margin-bottom: 14px;

  .cf-tab {
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin-right: 32px;
    padding-bottom: 12px;
    cursor: pointer;
    color: rgba(136, 136, 141, 1);
    
    &.active {
      border-bottom: 1px solid #fff;
      color: #fff;
    }
  }
}

.cf-tab-panel {
  &.hidden {
    display: none;
  }
}
