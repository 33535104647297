.model-form-definition {
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 16px;

  & > * {
    width: 360px;
  }
}
