@import 'common.scss';

.cohort-info {
  width: fit-content;
  position: absolute;
  top: -15px;
  right: -100px;

  .content {
    display: flex;
    gap: $xs;
    border-radius: $rounded-xl;
    padding: 8px 12px;
    color: #94d5db;

    /* Tag */
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.26px;

    border: 0.75px solid $Primary500;
    background: rgba(148, 213, 219, 0.15);
  }
}
