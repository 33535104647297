@import 'common.scss';

.user-menu {
  @extend .modal-box;
  position: absolute;

  .menu-header {
    display: flex;
    font-size: 14px;
    line-height: 1.3;
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid rgba(48, 54, 61, 0.48);
  }

  .user-avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 50%;
    color: $dark100;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: normal;
    background: $dark20;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .user-fullname {
    color: #f9fafb;
    font-weight: 600;
  }

  .user-username {
    color: #88888d;
  }

  .menu-item {
    display: flex;
    padding-left: 16px;
    line-height: 44px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    &:hover {
      background-color: rgba(177, 186, 196, 0.12);
    }
  }
}


