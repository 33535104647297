@import 'common.scss';

.cf-no-border-button {
  background-color: $button-bg-default;
  color: $button-fg-default;
  border-radius: 6px;
  border: 1px solid $button-bg-default;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: $semibold;
  font-size: 18px;
  font-family: 'Open Sans';
  cursor: pointer;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;

  .cf-no-border-button-input {
    margin-left: 4px;
    margin-right: 4px;
  }

  &:hover {
    background-color: $button-bg-hover;
    color: $button-fg-hover;
  }

  &:active {
    background-color: $button-bg-active;
    color: $button-fg-active;
  }

  &:focus {
    background-color: $button-bg-focused;
    color: $button-fg-focused;
  }

  .button-icon {
    margin-right: 4px;
    margin-left: 4px;
  }
}

.is-disabled {
  pointer-events: none;
  color: $button-fg-disabled;
}
