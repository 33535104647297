@import 'common.scss';

.model-features {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .selected-metrics-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .cf-titled-component {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .trait-list {
        display: flex;
        flex-direction: column;
      }
    }

    .empty-label {
      border-top: 1px solid $dark65;
      padding: 1rem;
      font-size: 14px;
      text-align: center;
    }
  }
}
