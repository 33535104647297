@import 'common.scss';

.chat-switch {
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 50%;
  color: $dark100;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: normal;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  svg {
    width: 24px;
  }

  &.system {
    background: $dark85;

    &.light {
      background-color: $Gray500;
      border: 1px solid $Gray400;
    }

    &.dark {
      background-color: $Gray700;
      border: 1px solid $Gray600;
    }

    &.open {
      background: $dark20;
    }
  }

  &.user {
    background: $dark20;
  }
}
