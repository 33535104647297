@import 'common.scss';

.cf-modal {
  @extend .card;
  position: relative;

  &-xmark {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    padding: 15px;

    &:hover {
      color: rgba(255, 255, 255, .8);
    }
  }
}
