@import 'common.scss';

.participants-manual-picker {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .subject-type {
    width: 300px;
  }

  .bad-users {
    background-color: $error-color;
    padding: 5px;
    border-radius: 3px;

    .highlight {
      font-weight: $semibold;
    }

    .highlight:not(:last-child) {
      &::after {
        content: ',';
      }
    }
  }
}
