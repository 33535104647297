@import 'common.scss';

.cf-button-group {
  display: flex;

  &-button {
    padding: 10px 20px;
    border: 1px solid $dark70;
    color: $dark20;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    background-color: $dark100;

    &.active {
      background-color: $cfCyan;
      color: $dark100;
      font-weight: 700;
    }

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }

    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }
}
