@import 'common.scss';

.model-monitor-definition {
  .model-definition-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .model-details .content {
      padding: 0;
    }

    .model-runs {
      display: flex;
      gap: 8px;

      .success {
        background-color: #039874;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      .in-progress {
        background-color: #fbbc05;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      .failed {
        background-color: #d6424b;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}
