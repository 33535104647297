@import 'common.scss';

.status-tag {
  width: min-content;
  position: relative;
  padding: 2px 8px 2px 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 16px;
  color: var(--text-color);
  background-color: var(--background-color);
  text-transform: capitalize;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    background-color: var(--dot-color);
    border-radius: 50%;
    left: 7px;
    top: 8px;
  }
}
