@import 'common.scss';

.new-nudge-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .nudge-body-title {
    font-size: 18px;
    font-weight: $semibold;
    margin: 20px 0;
  }
  .oneline {
    display: flex;
    gap: 24px;

    & > * {
      flex: 1 1 auto;
    }
  }

  .editable-area {
    width: 100%;
    min-height: 100px;
    border-radius: $border-radius;
    border: 1px solid $dark65;
    background-color: $dark100;
    font-size: 14px;
    color: $textPrimary;
    padding: 15px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid $cfCyan;
      box-shadow: 0px 0px 6px 0px rgba($cfCyan, 0.25);
      outline: none;
    }
  }

  .message-options {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    & > * {
      width: 100%;
    }
  }
}
