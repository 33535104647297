@import 'common.scss';

.markov-state {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid $input-outline;
  border-radius: $rounded-md;
  padding: 8px;

  & > :first-child {
    flex: 1 1 auto;
  }
}
