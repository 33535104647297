@import 'common.scss';

.model-option {
  &__item {
    display: flex;
    gap: 5px;
    align-items: flex-end;

    & :last-child {
      font-size: smaller;
      color: $dark20;
    }
  }
}
