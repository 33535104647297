@import 'common.scss';

.metrics-policy {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .secondary-metrics {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .secondary-metric {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;

    & > :first-child {
      flex-grow: 1;
    }

    .metric-controls {
      align-self: flex-end;
      margin-bottom: 8px;
    }
  }

  .collapsible-content.expanded {
    background-color: transparent;
  }

  .selected-metrics-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .cf-titled-component {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .trait-list {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .empty-label {
    border-top: 1px solid $dark65;
    padding: 1rem;
    font-size: 14px;
    text-align: center;
  }

  &-subtitle {
    margin-bottom: 34px;
    color: rgba(208, 208, 209, 1);
  }

  &-tabpanel {
    margin-bottom: 56px;

    &-filters {
      display: flex;

      & > :first-child {
        min-width: 200px;
        //        flex: 1 1 auto;
      }
    }
  }
}
