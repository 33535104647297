@import 'common.scss';

.org-proj-picker {
  position: absolute;
  top: 100px;
  left: 500px;

  display: grid;

  &.single-picker {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px;
    grid-template-areas: 'proj';
    width: 200px;
  }

  &.double-picker {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'proj org';
    width: 400px;
    gap: 20px;
  }

  box-shadow: 0px 0px 6px 3px rgba(16, 24, 40, 0.25);

  .picker {
    @extend .modal-box;

    & > :hover {
      background-color: $dark70;
    }

    & > :hover:first-child {
      border-radius: 8px 8px 0 0;
    }

    & > :hover:last-child:not(:only-of-type) {
      border-radius: 0 0 8px 8px;
    }
  }

  .org {
    grid-area: org;

    & > * {
      padding: 20px;
      border-bottom: 1px solid gray;
    }

    & > :last-child {
      border-bottom: none;
    }
  }

  .project {
    grid-area: proj;

    & > :first-child {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid gray;

      & > :first-child {
        flex: 1 1 auto;
      }
    }

    & > * {
      padding: 20px;
    }
  }
}
