@import 'common.scss';

.cf-button {
  background-color: $dark90;
  color: $button-fg-default;
  border-radius: 6px;
  border: 1px solid $button-border-color;
  font-weight: $semibold;
  font-size: 14px;
  font-family: 'Open Sans';
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;

  .cf-button-input {
    margin-left: 4px;
    margin-right: 4px;
  }

  &.role-primary {
    background-color: $cfCyan;
    color: $dark95;

    &:hover {
      filter: brightness(115%);
    }

    &:active {
    }

    &:focus {
    }

    &.is-disabled {
      background-color: $cfCyan_disabled;
      pointer-events: none;
    }
  }

  &.role-neutral {
    background-color: $dark85;
    color: white;
    border: none;

    &:hover {
      filter: brightness(115%);
    }

    &:active {
    }

    &:focus {
    }
  }

  &.role-cyan {
    background-color: $cfCyan;
    color: $dark100;
    border: none;

    &:hover {
      filter: brightness(115%);
    }

    &:active {
    }

    &:focus {
    }
  }

  &.role-secondary {
    &:hover {
      background-color: $button-bg-hover;
      color: $button-fg-hover;
    }

    &:active {
      background-color: $button-bg-active;
      color: $button-fg-active;
    }

    &:focus {
      background-color: $button-bg-focused;
      color: $button-fg-focused;
    }

    &.is-disabled {
      background-color: $dark50;
      color: $dark65;
      pointer-events: none;
    }
  }

  &.role-borderless {
    background-color: transparent;
    color: $cfCyan;
    border: none;
    padding-left: 0;
    padding-right: 0;
    justify-content: left;
    min-width: unset;

    &:hover {
      filter: brightness(115%);
    }

    &:active {
    }

    &:focus {
    }
  }

  &.role-only-icon {
    background-color: transparent;
    color: $dark50;
    border: none;
    padding-left: 0;
    padding-right: 0;
    justify-content: left;
    min-width: unset;

    &:hover {
      filter: brightness(115%);
    }

    &:active {
    }

    &:focus {
    }
  }

  .button-icon {
    margin-right: 4px;
    margin-left: 4px;

    max-width: 16px;

    &.animate {
      animation: spin 1s infinite linear;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
