.chunk-item {
  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;

    .title {
      font-weight: 600;
    }
  }
}
