@import 'common.scss';

.sync-status {
  width: 20px;

  &__icon {
    color: $error-color;
  }
}

.detailed-sync-status {
  width: 700px;
  height: 700px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  &__footer {
    display: flex;
    justify-content: right;
  }
}
