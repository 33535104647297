@import 'common.scss';

.expanded-view {
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;

  &-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &-table {
    margin: 24px 0;
  }

  &-stacktrace {
    margin-top: 10px;
    padding: 20px 24px;
    background-color: $cardBkgd;
    border: 1px solid $dark65;
    border-radius: 10px;
    height: 300px;
    overflow-y: scroll;
  }
}
