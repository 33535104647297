@import 'common.scss';

.scheduling-builder {
  display: flex;
  flex-direction: row;

  align-content: stretch;
  align-items: flex-start;
  gap: 10px;

  &-set {
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 24px;
    }
  }

  &-sets {
    display: flex;
    flex-direction: column;

    .scheduling-set:nth-child(2) .set-title::after {
      background-color: $cfYellow;
    }

    .scheduling-set:nth-child(3) .set-title::after {
      background-color: $cfRed;
    }

    .scheduling-set:nth-child(1) .set-title::after {
      content: '';
      display: block;
    }

    .text-md {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .set-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .row {
      display: flex;
      gap: 16px;
      margin-bottom: 24px;

      &:last-child {
        margin: 0;
      }
    }

    .small-input {
      width: 80px;
      height: 100%;
      display: flex;
    }

    .checkbox {
      align-self: flex-end;
      padding: 12px 0;
    }
  }

  &-calendar {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .set-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .text-md {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .selected-dates {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .timezone {
    margin-bottom: 24px;

    &.calendar {
      margin-bottom: 0;
    }

    .cf-select {
      min-width: 300px;

      .cf-select-items {
        max-height: 200px;
      }
    }
  }
}

.intervention-schedule-type {
  margin-bottom: 24px;
}
