@import 'common.scss';

.ios-nudge-preview {
  height: 100%;
  border-radius: 8px;
  padding: 32px 32px 0px 32px;
  box-sizing: border-box;
  width: 415px;
  height: 300px;

  & > .title {
    text-align: center;
    color: $dark40;
    font-size: 14px;
    font-style: italic;
    line-height: 40px;
  }

  & > .phone {
    background-color: $cardBkgd;

    height: 100%;
    border-radius: 70px 70px 0 0;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 14px 14px 0 14px;
    box-sizing: border-box;
    border: 1px solid #8eadc1;
    border-bottom: none;

    & > .screen {
      background-color: $dark80;
      background-image: url(images/ios.png);
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      height: 100%;
      border-radius: 55px 55px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .header {
        position: relative;
        width: 50%;
        display: flex;
        height: 30px;
        justify-items: center;
        align-items: center;
        justify-content: center;

        & > .pre-header {
          min-width: 10px;
          height: 30px;
          background-color: $cardBkgd;
        }

        & > .content {
          flex: 1 1 auto;
          height: 30px;
          display: flex;
          background-color: $cardBkgd;
          border-radius: 0 0 20px 20px;

          gap: 5px;
          justify-items: center;
          align-items: center;

          & > .speaker {
            background-color: #262c2d;
            height: 6px;
            width: 43px;
            border-radius: 3px;
          }
        }
      }

      & > .notification {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 80%;
        font-size: 13px;
        color: black;
        margin-top: 35px;
        padding: 20px;

        border-radius: 13px;
        background: rgba(245, 245, 245, 0.6);
        backdrop-filter: blur(16.600963592529297px);

        & > .header {
          color: #5b5b5b;
          text-transform: uppercase;
          .alert-icon {
            padding-right: 5px;
          }

          .title {
            font-weight: bold;
          }
        }
      }
    }
  }
}
