@import 'common.scss';

.box {
  display: flex;
  align-items: center;
  padding: 2px 5px 2px 5px;
  background-color: $dark100;
  color: white;
  border-radius: $border-radius;
  border: 1px solid $dark65;
  min-height: 34px;
}
