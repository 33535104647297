@import 'common.scss';

.models {
  @extend .card;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-controls {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    margin-right: auto;
    font-size: 22px;
    font-weight: 600;
    line-height: 37px;
  }

  &-search {
    margin-right: 24px;
  }

  &-extended {
    margin-top: 19px;
    display: flex;
    flex-direction: column;

    &-controls {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding-right: 24px;
      min-width: 200px;
    }
  }

  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .model-class {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .model-tags {
    display: flex;
    gap: 5px;
  }

  .inline {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
