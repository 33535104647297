@import 'common.scss';


.traits-table {
  .cf-select-searchField {
    width: 300px;
    border-color: $dark65;
  }

  .actions {
    display: flex;
    gap: 16px;

    .search {
      margin-left: auto;
    }
  }
}

.metrics-picker-title {
  display: flex;
  flex-direction: column;
  grid-area: 0.5rem;
  padding: 1rem;

  .title {
    font-size: 18px;
    line-height: 26px;
  }

  .sub-title {
    font-size: 14px;
    line-height: 20px;
    color: $dark40;
  }
}
