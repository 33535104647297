@import 'common.scss';

.cf-header {
  display: flex;
  border-bottom: 0.5px solid $dark30;
  width: 100%;
  background-color: #12121b;
  padding: 0 40px;

  .selected {
    box-sizing: border-box;
    border-bottom: 2px solid $dark30;
  }

  .header-item {
    display: flex;
    align-items: center;
    margin-right: 24px;
    text-transform: capitalize;
    height: 78px;
    font-size: 18px;

    &.full {
      flex: 1 1 auto;
    }

    &.org {
      height: unset;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    margin-right: 24px;
    text-transform: capitalize;
  }

  .logo-img {
    height: 25px;
  }

  .header-link {
    font-weight: 600;
    color: rgb(240, 246, 252);
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .profile-avatar {
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 50%;
    color: $dark100;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: normal;
    background: $dark20;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .project-title {
    display: flex;
    align-items: center;
    gap: 4px;

    .sub-text {
      color: #d0d0d1;
      font-size: 12px;
      margin-top: 2px;
    }
  }
}
