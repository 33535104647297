@import 'common.scss';

.scatter-legend {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  .legend-item {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0px 10px;

    cursor: pointer;

    &__circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &.selected {
      color: $primary;
      font-weight: $semibold;
    }
  }
}
