@import 'common.scss';

.detailed-churn-prediction {
  overflow-y: auto;
  background-color: $cardNestedBkgd;
  min-width: 1000px;
  padding: 16px;
  border-radius: 8px;

  .churn-prediction-chart {
    background-color: $cardNestedBkgd;
    border: none;
    padding: 0px;
  }

  .load-backdrop {
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
